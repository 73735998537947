import {
    SET_DASHBOARD_ENTITIES,
    INITIAL_DASHBOARD_ENTITY_STATE,
    SET_DASHBOARD_FILTERS,
    SET_COMPARE_FILTERS,
    SET_ANALYZE_SEARCH_VALUE,
    SET_ANALYZE_SCHOOL_SEARCH_VALUE,
    SET_ANALYZE_ENTITY,
    SET_ANALYZE_SCHOOL_ENTITY,
    SET_ANALYZE_ENTITY_DATA,
    SET_ANALYZE_SCHOOL_ENTITY_DATA,
    SET_ANALYZE_SEARCH_CANDIDATES,
    ADD_COMPARE_ENTITY,
    SET_COMPARE_ENTITIES,
    REMOVE_COMPARE_ENTITY,
    SET_COMPARE_ENTITY,
    CLEAR_COMPARE_ENTITIES,
    SET_COMPARE_SEARCH_VALUE,
    SET_COMPARE_SEARCH_CANDIDATES,
    SET_APP_CONTEXT,
} from './entityConstants'

import produce from 'immer'

export default produce((draft = INITIAL_DASHBOARD_ENTITY_STATE, action) => {
    switch (action.type) {
        case SET_APP_CONTEXT: 
            draft['context'] = action.context;
            break;

        // Dashboard
        case SET_DASHBOARD_ENTITIES:
            draft['all'] = {
                items: action.entities.items,
                total: action.entities.total,
                active_states: action.entities.active_states,
            }
            draft['dashboardLoading'] = false;
            break
        case SET_DASHBOARD_FILTERS:
            draft['filters'] = action.filters
            draft['dashboardLoading'] = true;
            break
        case SET_COMPARE_FILTERS:
            draft['compareFilters'] = action.filters
            break;
        // Analyze
        case SET_ANALYZE_SEARCH_VALUE:
            draft['analyzeSearch'] = action.searchValue
            break
        case SET_ANALYZE_SCHOOL_SEARCH_VALUE:
            if (action.search) {
                draft['analyzeSchoolSearch'] = action.search.value;
            }
            break
        case SET_ANALYZE_ENTITY:
            draft['analyzeEntity'] = action.entity
            if (action.entity === null) {
                draft['analyzeData'] = null
            }
            break
        case SET_ANALYZE_SCHOOL_ENTITY:
            if (!draft['analyzeSchoolEntity']) {
                draft['analyzeSchoolEntity'] = {}
            }
            if (action.entity) {
                draft['analyzeSchoolEntity'][action.entity.school_id] = action.entity
            }
            break
        case SET_ANALYZE_SEARCH_CANDIDATES:
            draft['analyzeCandidates'] = action.searchCandidates
            break
        case SET_ANALYZE_ENTITY_DATA:
            draft['analyzeData'] = action.data
            break
        case SET_ANALYZE_SCHOOL_ENTITY_DATA:
            if (!draft['analyzeSchoolEntityData']) {
                draft['analyzeSchoolEntityData'] = {}
            }
            if (action.entityData) {
                draft['analyzeSchoolEntityData'][action.entityData.id] = action.entityData.data;
            }
            
            break
        // Compare
        case ADD_COMPARE_ENTITY:
            if (!draft['compareEntities']) {
                draft['compareEntities'] = {
                    items: [],
                    total: 0
                }; 
            }
            draft['compareEntities'].items.unshift(action.entityData);
            draft['compareEntities'].total += 1;
            break
        case SET_COMPARE_ENTITIES:
            if (action.compareEntities && action.compareEntities.items && action.compareEntities.total) {
                draft['compareEntities'] = action.compareEntities;
            }
            else {
                draft['compareEntities'] = {
                    items: [],
                    total: 0
                }
            }
            break
        case SET_COMPARE_SEARCH_VALUE:
            draft['compareSearch'] = action.searchValue
            break
        case SET_COMPARE_SEARCH_CANDIDATES:
            draft['compareCandidates'] = action.searchCandidates
            break
        case REMOVE_COMPARE_ENTITY:
            if (draft['compareEntities']) {
                let target = -1;
                for (var i = 0; i < draft['compareEntities'].items.length; i++) {
                    if (draft['compareEntities'].items[i].id === action.entityId) {
                        target = i;
                        break;
                    }
                }
                if (target >= 0) {
                    draft['compareEntities'].items.splice(target, 1);
                }
            }
            break
        case SET_COMPARE_ENTITY:
            if (action.compareEntities) {
                draft['compareEntities'] = action.compareEntities;
            }
            else {
                draft['compareEntities'] = [];
            }
            break;
        case CLEAR_COMPARE_ENTITIES:
            draft['compareEntities'] = {
                items: [],
                total: 0
            }; 
            break
        default:
            break
    }
    return draft
})
