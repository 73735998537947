import { logout } from '../auth/authActions'
import {
    SET_APP_CONTEXT,
    SET_DASHBOARD_ENTITIES,
    SET_DASHBOARD_FILTERS,
    SET_COMPARE_FILTERS,
    SET_ANALYZE_ENTITY,
    SET_ANALYZE_SCHOOL_ENTITY,
    SET_ANALYZE_SEARCH_VALUE,
    SET_ANALYZE_SCHOOL_SEARCH_VALUE,
    SET_ANALYZE_SEARCH_CANDIDATES,
    SET_ANALYZE_ENTITY_DATA,
    SET_ANALYZE_SCHOOL_ENTITY_DATA,
    SET_COMPARE_SEARCH_VALUE,
    ADD_COMPARE_ENTITY,
    SET_COMPARE_ENTITIES,
    SET_COMPARE_SEARCH_CANDIDATES,
    REMOVE_COMPARE_ENTITY,
    SET_COMPARE_ENTITY,
    CLEAR_COMPARE_ENTITIES,
} from './entityConstants'
import { notification } from 'antd'
import ApiService from '../apiService'
import { ERROR_COLOR } from '../../shared/constants'
import { store } from '../persistentStore'

export const ENTITY_TARGET = {
    DASHBOARD: 1,
    COMPARE: 2,
}

export const setContext = (context) => {
    store.dispatch({
        type: SET_APP_CONTEXT,
        context
    });
}

// Dashboard
export const setDashboardEntities = (entities) => {
    return {
        type: SET_DASHBOARD_ENTITIES,
        entities,
    }
}

export const setDashboardFilters = (filters) => {
    return {
        type: SET_DASHBOARD_FILTERS,
        filters,
    }
}

export const setDashboardFiltersAndRefresh = (filters) => async (dispatch) => {
    dispatch(setDashboardFilters(filters))
    dispatch(reloadEntites(filters))
}

export const setCompareFilters = (filters) => async(dispatch) => {
    dispatch({
        type: SET_COMPARE_FILTERS,
        filters,
    });
}

export const setCompareFiltersAndRefresh = (filters) => async (dispatch) => {
    dispatch(setCompareFilters(filters))
    dispatch(compareSearch(filters))
}


// params will be search params that we will persist
export const getDashboardEntites = (token, params) => async (dispatch) => {
    try {
        const getDashboardEntites = await ApiService.getDashboardEntities(
            token,
            params
        )
        const { data } = getDashboardEntites
        dispatch(setDashboardEntities(data))
        return data
    } catch (error) {
        console.warn(error)
        logout()
        notification.error({
            duration: 0,
            placement: 'topRight',
            message: 'Login Error',
            description: 'Please try logging out and in again.',
            style: { backgroundColor: ERROR_COLOR },
        })
    }
}

export const reloadEntites = (filters) => async (dispatch) => {
    try {
        const state = store.getState()
        const token = state.auth.authToken
        const params = filters; // state.entities.filters
        const getDashboardEntites = await ApiService.getDashboardEntities(
            token,
            params
        )
        const { data } = getDashboardEntites

        dispatch(setDashboardEntities(data))        
        return data
    } catch (error) {
        console.warn(error)
        logout()
        notification.error({
            duration: 0,
            placement: 'topRight',
            message: 'Login Error',
            description: 'Please try logging out and in again.',
            style: { backgroundColor: ERROR_COLOR },
        })
    }
}

export const compareSearch = (filters, setLoading) => async (dispatch) => {
    try {
        const state = store.getState()
        const token = state.auth.authToken
        let params = {};
        // copy over filters so we do not lose saved filters
        params = Object.assign(params, filters); // state.entities.filters
        if (params.locationType === 'state') {
            delete params.zipcode;
            delete params.radius;
        }
        else {
            delete params.state;
        }
        const searchResults = await ApiService.compareSearch(
            token,
            params
        )
        const { school_result, cmo_result, obligated_group_result, total } = searchResults.data;
        let schools = (school_result && school_result.length > 0) ? JSON.parse(school_result) : [];
        let cmos = (cmo_result && cmo_result.length > 0) ? JSON.parse(cmo_result) : [];
        let ogs = (obligated_group_result && obligated_group_result.length > 0) ? JSON.parse(obligated_group_result) : [];
        let data = {items: schools.concat(cmos).concat(ogs), total: total};
        console.log('compare search result ' + data);
        dispatch(setCompareEntities(data));  
        if (setLoading) {
            setLoading(false);
        }      
        return data;
    } catch (error) {
        console.warn(error)
        logout()
        notification.error({
            duration: 0,
            placement: 'topRight',
            message: 'Login Error',
            description: 'Please try logging out and in again.',
            style: { backgroundColor: ERROR_COLOR },
        })
    }
}

// Analyze
const setAnalyzeSearchValue = (searchValue) => {
    return {
        type: SET_ANALYZE_SEARCH_VALUE,
        searchValue,
    }
}

const setAnalyzeSchoolSearchValue = (searchValue, school_id) => {
    let search = {};
    search.value = searchValue;
    search.id = school_id;
    return {
        type: SET_ANALYZE_SCHOOL_SEARCH_VALUE,
        search,
    }
}

const setAnalyzeSearchCandidates = (searchCandidates) => {
    return {
        type: SET_ANALYZE_SEARCH_CANDIDATES,
        searchCandidates,
    }
}

const reloadAnalyzeCandidates = () => async (dispatch) => {
    try {
        const state = store.getState()
        const { entities } = state
        const { analyzeSearch } = entities
        if (!analyzeSearch) {
            return null
        }
        const token = state.auth.authToken
        const params = {
            name: state.entities.analyzeSearch,
        }
        const getEntityQuery = await ApiService.getEntityQuery(token, params)
        const { data } = getEntityQuery
        dispatch(setAnalyzeSearchCandidates(data))
        return data
    } catch (error) {
        console.warn(error)
        // don't log out since this is just called next key stroke
    }
}

export const setAnalyzeSearchAndRefresh = (searchValue) => async (dispatch) => {
    dispatch(setAnalyzeSearchValue(searchValue))
    dispatch(reloadAnalyzeCandidates())
}

export const setAnalyzeSchoolSearchAndRefresh = (searchValue) => async (dispatch) => {
    dispatch(setAnalyzeSchoolSearchValue(searchValue))
    dispatch(reloadAnalyzeCandidates())
}

export const setAnalyzeEntity = (entity) => {
    return {
        type: SET_ANALYZE_ENTITY,
        entity,
    }
}

export const setAnalyzeEntityData = (data) => {
    return {
        type: SET_ANALYZE_ENTITY_DATA,
        data,
    }
}

export const setAnalyzeSchoolEntity = (entity) => {
    return {
        type: SET_ANALYZE_SCHOOL_ENTITY,
        entity,
    }
}

export const setAnalyzeSchoolEntityData = (data, school_id) => {
    let entityData = {};
    entityData.data = data;
    entityData.id = school_id;
    return {
        type: SET_ANALYZE_SCHOOL_ENTITY_DATA,
        entityData
    }
}

const reloadEntityData = (entityType, setter, school_id) => async (dispatch) => {
    try {
        const state = store.getState()
        const { auth, entities } = state
        const { authToken } = auth
        const { analyzeEntity, analyzeSchoolEntity } = entities
        if (entityType === "generic" && !analyzeEntity) {
            return null
        }
        if (entityType === "school" && !analyzeSchoolEntity) {
            return null;
        }

        const getAnalyzeInfo = await ApiService.getAnalyzeInfo(
            authToken,
            entityType === 'generic' ? analyzeEntity : analyzeSchoolEntity[school_id]
        )
        const { data } = getAnalyzeInfo
        dispatch(setter(data, school_id))
        return data
    } catch (error) {
        console.warn(error)
        logout()
        notification.error({
            duration: 0,
            placement: 'topRight',
            message: 'Login Error',
            description: 'Please try logging out and in again.',
            style: { backgroundColor: ERROR_COLOR },
        })
    }
}

export const setAnalyzeEntityAndLoadData = (entity) => async (dispatch) => {
    dispatch(setAnalyzeEntityData(null))
    dispatch(setAnalyzeSearchValue(entity.name))
    dispatch(setAnalyzeEntity(entity))
    return dispatch(reloadEntityData('generic', setAnalyzeEntityData))
}

// For CMO/OG school analysis window
export const setAnalyzeSchoolEntityAndLoadData = (entity) => async (dispatch) => {
    dispatch(setAnalyzeSchoolEntityData(null, entity.school_id))
    dispatch(setAnalyzeSchoolSearchValue(entity.name, entity.school_id))
    dispatch(setAnalyzeSchoolEntity(entity))
    return dispatch(reloadEntityData('school', setAnalyzeSchoolEntityData, entity.school_id))
}

// Compare
const setCompareSearchValue = (searchValue) => {
    return {
        type: SET_COMPARE_SEARCH_VALUE,
        searchValue,
    }
}

const setCompareSearchCandidates = (searchCandidates) => {
    return {
        type: SET_COMPARE_SEARCH_CANDIDATES,
        searchCandidates,
    }
}

export const addCompareSchool = (entityData) => {
    return {
        type: ADD_COMPARE_ENTITY,
        entityData,
    }
}

export const setCompareSchools = (entityData) => {
    return {
        type: SET_COMPARE_ENTITIES,
        entityData,
    }
}

export const removeCompareEntity = (entityId) => {
    return {
        type: REMOVE_COMPARE_ENTITY,
        entityId,
    }
}

export const setCompareEntities = (compareEntities) => {
    return {
        type: SET_COMPARE_ENTITIES,
        compareEntities,
    }
}

export const clearCompareEntities = () => {
    return {
        type: CLEAR_COMPARE_ENTITIES,
    }
}

const reloadCompareCandidates = () => async (dispatch) => {
    try {
        const state = store.getState()
        const { entities } = state
        const { compareSearch } = entities
        if (!compareSearch) {
            return null
        }
        const token = state.auth.authToken
        const params = {
            name: compareSearch,
        }
        const getEntityQuery = await ApiService.getEntityQuery(token, params)
        const { data } = getEntityQuery
        dispatch(setCompareSearchCandidates(data))
        return data
    } catch (error) {
        console.warn(error)
        // don't log out since this is just called next key stroke
    }
}

export const setCompareSearchAndRefresh = (searchValue) => async (dispatch) => {
    dispatch(setCompareSearchValue(searchValue))
    dispatch(reloadCompareCandidates())
}

export const addAndLoadCompareEntity = (token, entityInfo) => async (
    dispatch
) => {
    try {
        const getEntity = await ApiService.getCompareEntityData(
            token,
            entityInfo
        )
        getEntity.data.entityInfo = entityInfo
        dispatch(addCompareSchool(getEntity.data))
        return getEntity.data
    } catch (error) {
        console.warn(error)
        logout()
        notification.error({
            duration: 0,
            placement: 'topRight',
            message: 'Login Error',
            description: 'Please try logging out and in again.',
            style: { backgroundColor: ERROR_COLOR },
        })
    }
}

export const exportCompareEntity = async (token, entities) => {
    try {
        if (entities) {
            let sids = []
            let cmoIds = []
            let ogIds = []
            for (var key in entities.items) {
                let entity = entities.items[key]
                if (entity) {
                    if (entity.school_id) {
                        sids.push(entity.school_id)
                    } else if (entity.cmo_id) {
                        cmoIds.push(entity.cmo_id)
                    }
                    else if (entity.obligated_group_id) {
                        ogIds.push(entity.obligated_group_id);
                    }
                }
            }

            let data = {}
            data.school_id = sids
            data.cmo_id = cmoIds
            data.obligated_group_id = ogIds
            const excelFile = await ApiService.postCompareEntityData(
                token,
                data
            )

            return excelFile.data
        }
        return null
    } catch (error) {
        console.warn(error)
        logout()
        notification.error({
            duration: 0,
            placement: 'topRight',
            message: 'Login Error',
            description: 'Please try logging out and in again.',
            style: { backgroundColor: ERROR_COLOR },
        })
    }
}
